import React, {Fragment} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import WalletSlide from '../Wallet/WalletSlide';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';

import WalletButton from '../Wallet/WalletButton';
import WithdrawalButton from '../Withdrawal/WithdrawalButton';
import Card from '@mui/material/Card';
import { CardActions } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';



import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TransferList = () => {

  const addressText = (address) => {
    let text = String(address) || address;
    let last6 = text.slice(text.length - 4);
    let first3 = text.substring(0,3);
    return first3+'XXX'+last6;
  }

    const user = useSelector((state) => state.user.user);

    const debits = useSelector((state) => state.history.debits);
    const credits = useSelector((state) => state.history.credits);

    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: user.currency, currencySign: 'standard' }).format(amount);
    const formatDate = (date) => new Date(date).toLocaleString('en-GB', {
      hour12: false,
    });
    const allTransferList = debits.concat(credits);

    const combinedTransferList = allTransferList.sort((b, a) => {
        return (a.created_at).localeCompare((b.created_at))
    })

    
  return (
    <div>
<Grid container px={2} mt={-4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={8}>
<Typography mt={4} px={3} sx={{marginTop: '75px', paddingX: '10px', fontWeight: 'bold', fontSize: 20, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
        TRANSACTION HISTORY
</Typography>

{combinedTransferList.length > 0 &&
    combinedTransferList.map(transaction => (
      <Accordion key={transaction.id} sx={{borderRadius: '10px', marginBottom: '10px'}} >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                        <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          
                          {parseInt(transaction.user_id) === parseInt(user.id) &&
                            <Avatar  sx={{ bgcolor: '#dd0a22' }}>
                             <RemoveCircleIcon/>
                            </Avatar>
                          }
                          
                      
                          {parseInt(transaction.receiver_id) === parseInt(user.id) &&
                          <Avatar  sx={{ bgcolor: '#37dc8a' }}>
                            <AddCircleIcon/>
                          </Avatar>
                           
                           }
                      
                          
                           
                        </ListItemAvatar>
                      
                        <ListItemText
                          primary={transaction?.account_number || transaction.interAccountNumber}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="caption" display="block"
                                color={parseInt(transaction.user_id) === parseInt(user.id)? 'error' : 'primary'}
                                
                              >
                                {/* {transaction.created_at} */}
                                { parseInt(transaction.user_id) === parseInt(user.id)? 'debit' : 'credit' }
                                
                                <Typography variant="caption" ml={1} sx={{color: 'gray'}}>
                                 {formatDate(transaction.created_at)}
                                </Typography>
                              </Typography>
                            </React.Fragment>
                          }
                        />
                          
                      
                          <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-even', alignContent: 'left', alignItems: 'left'}}>
                              <Typography mt={1} edge="end">
                              {formatPrice(transaction.amount || transaction.amount)}
                              </Typography>
                              <Typography color={transaction.status.toLowerCase() === 'approved' ? 'primary.success': 'secondary'}  edge="end">
                              {transaction.status}
                              </Typography>
                          </Box>
                    
                      </ListItem>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Divider/>

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Transfer Amount </Typography>
                            <Typography py={1} variant="caption" >{formatPrice(transaction.amount)}</Typography>
                          </Box>
                          <Divider/>

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Transfer Type</Typography>
                            
                            <Typography py={1} variant="caption" >{transaction.transfer_type}</Typography>
                          </Box>
                          <Divider/>

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Account Number</Typography>
                            
                            <Typography py={1} variant="caption" >{transaction.account_number}</Typography>
                          </Box>
                          <Divider/>

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Routine Number</Typography>
                            
                            <Typography py={1} variant="caption" >{transaction.routineNumber}</Typography>
                          </Box>
                          <Divider/>

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Bank Name</Typography>
                            
                            <Typography py={1} variant="caption" >{transaction.bankName}</Typography>
                          </Box>
                          <Divider/>

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Receiver Email</Typography>
                            
                            <Typography py={1} variant="caption" >{transaction.receiverEmail}</Typography>
                          </Box>

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Description</Typography>
                            
                            <Typography py={1} variant="caption" >{transaction.description}</Typography>
                          </Box>

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Transaction Date</Typography>
                            
                            <Typography py={1} variant="caption" >{formatDate(transaction.created_at)}</Typography>
                          </Box>
                        
                        </AccordionDetails>
                      </Accordion>

))}
</Grid>
</Grid>
</div>
  )
}

export default TransferList
