import React, {useEffect, useState} from 'react'
import ReactApexChart from 'react-apexcharts';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useSelector } from 'react-redux';
import Navigations from './Navigations';


import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';


import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';


import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Divider } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import axios from "axios";

const TransactionCharts = () => {
    const [ip, setIP] = useState("");
    const debitsAnalysis = useSelector((state) => state.chartsAnalysis.salesAnalysis);
    const creditstsAnalysis = useSelector((state) => state.chartsAnalysis.fundingsAnalysis);
    const user = useSelector((state) => state.user.user);

    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: user.currency, currencySign: 'standard' }).format(amount);
    const fundingBal = useSelector((state) => state.wallet.fundingBalance);
    const subBal = useSelector((state) => state.wallet.subscriptionBalance);
    const refBal = useSelector((state) => state.wallet.referralBalance);
    const mainBal = useSelector((state) => state.wallet.withdrawableBalance);
    const loanBalance = useSelector((state) => state.wallet.loanBalance);
    
    let salesData = [];
    let fundingsData = [];

    debitsAnalysis.forEach(element => {
        salesData.push(element.amount);
    });

    creditstsAnalysis.forEach(element => {
        fundingsData.push(element.amount);
    });


    const series =  [{
        name: 'Total Debits',
        data: salesData
      },
      {
        name: 'Total Credits',
        data: fundingsData
      }
    ];

    const options = {
        chart: {
            foreColor: '#939c92',
          type: 'bar',
          height: 350,
        },
        
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        tooltip: {
            theme: 'dark'
          },
        xaxis: {
            theme: 'dark',
          categories: ['Jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yaxis: {
          title: {
            text: user.currency
          }
        },
        fill: {
          opacity: 1
        },
        
      };

      const getData = async () => {
        fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => setIP(data.ip))
        .catch(error => console.log(error))
      };

      useEffect(() => {
        getData();
      }, []);

  return (
    <div style={{marginTop: '72px'}}>
        <Grid container px={2} mt={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={8}>
            <Box sx={{display:'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>

              <ListItem alignItems="flex-start" mb={0}>
                <ListItemAvatar>
                  <Avatar alt="trader" src={user.profile} />
                </ListItemAvatar>
                <ListItemText
                  primary={user.name}
                  secondary={user.account_status === 'active' ?<span style={{color: '#0bec4b'}}><span style={{color: 'gray', marginRight: '5px'}}>{user.account_number}</span>({user.account_status})</span> :<span style={{color: '#ff0230', marginRight: '5px'}}><span style={{color: 'gray'}}>{user.account_number}</span> ({user.account_status})</span>}
                />
              </ListItem>
              

              <Box sx={{width: '100%'}}>
            
              <Typography ml={3} my={0} sx={{textAlign: 'center'}} variant="body2" color="text.secondary">
                Current Login IP: <span style={{color: '#0bcf60'}}>{ip}</span>
                </Typography>
              </Box>
            </Box>
            
            <Divider/>
            </Grid>
          </Grid>

        <Grid container px={{md: 5, xs: 1, sm: 2}} mt={0}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>        
            <Grid container xs={12} mt={1} md={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
                <Grid item xs={6} md={6}>
                    <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <Link  to={`/product`} component={RouterLink} underline="none" color="inherit">
                        <CardMedia sx={{display: 'flex', justifyContent: 'center', paddingY: '12px'}}>
                        <Avatar sx={{ bgcolor: '#10bd5a' }}>
                            <AccountBalanceWalletIcon sx={{ color: '#ffffff' }}/>
                        </Avatar>
                        </CardMedia>
                        <CardContent>
                        
                        <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                            {formatPrice(fundingBal)}
                        </Typography>
                        <Typography my={0} variant="body2" color="text.secondary">
                            Account Balance
                        </Typography>
                        
                        </CardContent>
                    </Link>    
                    </Card>
                </Grid>

                <Grid item xs={6} md={6}>
                    <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <Link  to={`/product`} component={RouterLink} underline="none" color="inherit">
                        <CardMedia sx={{display: 'flex', justifyContent: 'center', paddingY: '12px'}}>
                        <Avatar sx={{ bgcolor: '#10bd5a' }}>
                            <CurrencyExchangeIcon sx={{ color: '#ffffff' }}/>
                        </Avatar>
                        </CardMedia>
                        <CardContent >
                        
                        <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                            {formatPrice(subBal)}
                        </Typography>
                        <Typography my={0} variant="body2" color="text.secondary">
                            Cash Back
                        </Typography>
                        
                        </CardContent>
                    </Link>    
                    </Card>
                </Grid>

                <Grid item xs={6} md={6}>
                    <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <Link  to={`/product`} component={RouterLink} underline="none" color="inherit">
                        <CardMedia sx={{display: 'flex', justifyContent: 'center', paddingY: '12px'}}>
                        <Avatar sx={{ bgcolor: '#10bd5a' }}>
                            <CreditScoreIcon sx={{ color: '#ffffff' }}/>
                        </Avatar>
                        </CardMedia>
                        <CardContent >
                        
                        <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                            {formatPrice(loanBalance)}
                        </Typography>
                        <Typography my={0} variant="body2" color="text.secondary">
                            Loans
                        </Typography>
                        
                        </CardContent>
                    </Link>    
                    </Card>
                </Grid>

                
                <Grid item xs={6} md={6}>
                    <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <Link  to={`/product`} component={RouterLink} underline="none" color="inherit">
                        <CardMedia sx={{display: 'flex', justifyContent: 'center', paddingY: '12px'}}>
                        <Avatar sx={{ bgcolor: '#10bd5a' }}>
                            <MonetizationOnIcon sx={{ color: '#ffffff' }}/>
                        </Avatar>
                        </CardMedia>
                        <CardContent >
                        
                        <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                            {formatPrice(mainBal)}
                        </Typography>
                        <Typography my={0} variant="body2" color="text.secondary">
                            Investment Returns
                        </Typography>
                        
                        </CardContent>
                    </Link>    
                    </Card>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={8}>
                <Card sx={{marginX: '0px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography  ml={3} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'left', textTransform: 'capitalize'  }} gutterBottom variant="h6" component="div">
                        Account Overview ({user.currency})
                    </Typography>
                    <Typography ml={3} my={0} sx={{textAlign: 'left'}} variant="body2" color="text.secondary">
                        your account performance analysis for this year
                    </Typography>
                    <ReactApexChart options={options}  series={series} type="bar" height={350} />
                    </CardContent>
                </Card>
            </Grid>
            </Grid>
        
    </div>
  )
}

export default TransactionCharts
